import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton, TextField } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { setCustomerData, setCustomerCount } from '../../../store/CustomerData/action';
import { ILocalizationState } from '../../../store/Localization/types';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import Keyboard from "react-simple-keyboard";

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';


interface ILogo {
    logo: any;
}

interface CustomAttribute {
    name: string;
    value: string;
}

interface IState {
    customAttributes: CustomAttribute[];
    buttonDisabled: boolean;
    focusedValueKey: string;
    companyAttributesList: CompanyAttributeDTO[];
    input: string;
}

interface IProps {
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCustomerData: typeof setCustomerData;
    setCustomerCount: typeof setCustomerCount;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IProps & IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILogo & ILayoutDataState & IConfigDataState;

class CustomFieldsPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        customAttributes: [],
        buttonDisabled: true,
        focusedValueKey: null,
        companyAttributesList: [],
        input: ""
    }

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();

    private createCustomer = (data: CustomerDTO): boolean => {
        //call api to check
        return true;
    }

    private mapAttributesToDTO = (attributeValues: CustomAttribute[], attributes: CompanyAttributeDTO[]): CustomerInfoDTO[] => {
        const infos: CustomerInfoDTO[] = [];
        const { currentFlow, layoutData } = this.props

        if ((layoutData.code === "Sef" && currentFlow === "GroupCheckin" as FlowType) || (layoutData.code === "Seftest" && currentFlow === "GroupCheckin" as FlowType)) {
            attributes.forEach(att => {
                infos.push(
                    ({
                        companyAttributeId: att.id,
                        attributeValue: attributeValues.filter(av => av.name === att.attributeName)[0].value
                    }));
            })
        } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
            attributes.forEach(att => {
                infos.push(
                    ({
                        companyAttributeId: att.id,
                        attributeValue: "no value"
                    }));
            })
        } else {
            attributes.forEach(att => {
                infos.push(
                    ({
                        companyAttributeId: att.id,
                        attributeValue: attributeValues.filter(av => av.name === att.attributeName)[0].value
                    }));
            })
        }
        return infos;
    }

    private onKeyboardInputChange = (input: string) => {
        const { focusedValueKey, customAttributes } = this.state;

        if (focusedValueKey) {
            this.setState({ input })
            var names = input
            const numberofnames = input.split(' ')

            var firstname
            var lastname
            //(Benjamin) TODO: simplify below to not do case magic
            if (numberofnames.length === 1) {
                if (input.length === 1) {
                    names = input.charAt(0).toUpperCase()
                } else if (input.length > 1) {
                    names = input.charAt(0).toUpperCase() + input.slice(1);
                }
            } else {
                for (var i = 0; i < numberofnames.length; i++) {
                    if (i === 0) {
                        firstname = numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                    } else if (i === 1) {
                        lastname = numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                    } else {
                        lastname += " " + numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                    }
                }
            }

            if (numberofnames.length > 1) {
                names = firstname + " " + lastname
            }

            customAttributes.filter(a => a.name === focusedValueKey)[0].value = names;
        }

        this.setState({ customAttributes });
        this.setButtonState();
    }

    private onFieldFocus = (key: string) => {
        const { customAttributes } = this.state;
        const { layoutData } = this.props

        const prevInput = customAttributes.filter(a => a.name === key)[0].value;
        if (layoutData.code !== "Dragsbaek") {
            (this.keyboard.current as any).setInput(prevInput ?? "");
        }

        //console.log(key);

        this.setState({ focusedValueKey: key });
    }

    private nextButtonClicked = () => {
        const { currentFlow, data, setCustomerData, setCustomerCount, setCurrentPage, conditionData, configData, departmentsData, language, setCurrentDepartment, layoutData } = this.props;
        const { customAttributes, companyAttributesList } = this.state;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        data.customerInfos = this.mapAttributesToDTO(customAttributes, companyAttributesList);
        setCustomerData(data);

        if (customAttributes.length > 0) {
            var numberOfCustomers = customAttributes[customAttributes.length - 1].value;
            if (currentFlow === 'GroupCheckin' && !isNaN(+numberOfCustomers)) {
                setCustomerCount(numberOfCustomers);
            } else {
                setCustomerCount(1);
            }
        }

        if (this.createCustomer(data)) {
            switch (currentFlow) {
                case "CheckinAppointment":
                    if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                case "CheckinNoAppointment":
                    if (ClientConfig.flow.informAllEnabled) {
                        if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                            setCurrentPage("ConditionVideoPage" as PageType);
                        } else {
                            setCurrentPage("MessagePage" as PageType);
                            break;
                        }
                    }
                    else {
                        if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                            if (departmentsData.length === 1) {
                                setCurrentDepartment(0);
                                setCurrentPage("EmployeePage" as PageType);
                            } else {
                                setCurrentPage("DepartmentPage" as PageType);
                            }
                        } else {
                            setCurrentPage("EmployeePage" as PageType);
                        }
                    }
                    break;
                case "GroupCheckin":
                    if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                case "LeverandoerCheckin":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "Event":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "Carpenter":
                    if (layoutData.code === "Meneta") {
                        setCurrentPage("CustomFlowcardPage" as PageType)
                        break;
                    }
                    break;
                case "Other":
                    if (layoutData.code === "Meneta") {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                default:
                    throw "CustomFieldsPage: current flow " + currentFlow + " not recognized"

            }
        }
    }

    private setButtonState = () => {
        const { buttonDisabled, customAttributes } = this.state;
        const { layoutData } = this.props;

        let allFieldsFilled = true;
        if (layoutData.code === "Reisswolf" && customAttributes.length === 0) {
            allFieldsFilled = false;
        }
        for (var attribute of customAttributes) {
            if (!(attribute.value && attribute.value)) {
                allFieldsFilled = false;
            }
        }

        if (layoutData.code === "EDForhandler") {
            allFieldsFilled = true;
        }

        if (allFieldsFilled) {
            if (buttonDisabled) {
                this.setState({ buttonDisabled: false });
            }
        }
        else {
            if (!buttonDisabled) {
                this.setState({ buttonDisabled: true });
            }
        }
    }

    private onAttributeChange = (newAttributeValue: string, attributeName: string) => {
        const { customAttributes } = this.state;
        const { layoutData } = this.props;

        customAttributes.filter(a => a.name === attributeName)[0].value = newAttributeValue;
        if (layoutData.code !== "Dragsbaek") {
            (this.keyboard.current as any).setInput(newAttributeValue ?? "");
        }

        this.setState({ customAttributes });
        this.setButtonState();
    }

    private addStyleButton(): any {
        const { layoutData } = this.props;

        if (layoutData.code === "SociatyOfLife") {
            return ({ position: 'absolute', top: 400, left: 1500 })
        } else if (layoutData.code === "OfficePartner" || layoutData.code === "Comm2ig") {
            return ({ position: 'absolute', top: '31.5%', left: '82%' })
        } else if (layoutData.code === "EDForhandler") {
            return ({ position: 'absolute', top: '38%', left: '93%' })
        } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
            return ({ position: 'absolute', top: '39%', left: '83%' })
        } else if (layoutData.code === "Kalundborg") {
            return ({ position: 'absolute', top: '39%', left: '83%' })
        }

    }

    private addKeyboard() {
        const { layoutData } = this.props;

        if (layoutData.code === "OfficePartner") {
            return (<div style={{ width: 1010, position: 'absolute', left: 35, top: '40%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>);
        } else if (layoutData.code === "EDForhandler") {
            return (<div style={{ position: 'absolute', top: '55%', width: '100%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>);
        } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
            return (<div style={{ position: 'absolute', top: '47%', width: 1500 }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>);
        } else if (layoutData.code === "Kalundborg" || layoutData.code === "Comm2ig") {
            return (<div style={{ position: 'absolute', top: '46%', width: 1500 }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>);
        } else if (layoutData.code === "Reisswolf") {
            return (<div style={{ marginTop: 200 }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>);
        } else if (layoutData.code === "Dragsbaek") {

        } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
            return (<KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} />);
        } else if (layoutData.code === "Meneta") {
            return (<div><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>)
        } else {
            return (<div style={{ paddingBottom: 120 }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.input} /></div>)
        }
    }

    private showAttributeName(name: string) {
        const { layoutData } = this.props;

        var nameTemp = name;
        if (layoutData.code === "EDForhandler" || layoutData.code === "Kalundborg") {
            nameTemp = "";
        }
        return nameTemp;
    }

    private renderAttributesTextFields = (): JSX.Element[] => {
        const { customAttributes } = this.state;
        const { layoutData } = this.props;

        if (layoutData.code === "OfficePartner" || layoutData.code === "Meneta") {
            return customAttributes.map(att =>
            (<div style={{ position: 'absolute', top: '28.5%' }}>
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    className="custom-text-field"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    placeholder={this.strings().companyPlaceholderExample} key={att.name}
                    label={""}
                    value={att.value}
                    onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    autoFocus />
            </div>));
        } else if (layoutData.code === "Kalundborg" || layoutData.code === "Comm2ig") {
            return customAttributes.map(att =>
            (<div style={{ position: 'absolute', top: '25%' }}>
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    className="custom-text-field"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    placeholder={this.strings().CompanyPlaceholder}
                    key={att.name}
                    label={""}
                    value={att.value}
                    onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    autoFocus />
            </div>));
        } else if (layoutData.code === "EDForhandler") {
            return customAttributes.map(att =>
            (<div style={{ position: 'absolute', top: '25%' }}>
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    className="custom-text-field"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    placeholder={this.strings().edCompanyPlaceholder}
                    key={att.name}
                    label={""}
                    value={att.value}
                    onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    autoFocus />
            </div>));
        } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
            return customAttributes.map(att =>
            (<TextField
                autoComplete="new-password"
                aria-autocomplete="none"
                className="custom-text-field"
                styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                key={att.name}
                placeholder={this.strings().groupPlaceholder}
                value={att.value}
                onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                autoFocus />)
            );
        } else if (layoutData.code === "Reisswolf") {
            return customAttributes.map(att =>
            (<div className="Reiswolf-txtfield"><TextField
                autoComplete="new-password"
                aria-autocomplete="none"
                className="custom-text-field"
                styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                key={att.name}
                value={att.value}
                onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                autoFocus /></div>)
            );
        } else {
            return customAttributes.map(att =>
            (<TextField
                autoComplete="new-password"
                aria-autocomplete="none"
                className="custom-text-field"
                styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                key={att.name}
                label={this.showAttributeName(att.name)}
                value={att.value}
                onChange={(e, v) => this.onAttributeChange(v, att.name)} onFocus={() => this.onFieldFocus(att.name)}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                autoFocus />)
            );
        }

    }

    private addCustomFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().customOfficeFieldsPageTitle}</h2>)
        } else if (layoutData.code === "EDForhandler" || layoutData.code === "Kalundborg") {
            return (<h2 style={{ color: ClientConfig.mainColor, fontWeight: 'bolder', position: 'absolute', top: '12%' }}>{this.strings().edInserValue}</h2>)
        } else if (layoutData.code === "Reisswolf") {
            // TODO: Special logo case
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
                <h2 className="Reisswolf-secondtitle" style={{ color: ClientConfig.mainColor, fontFamily: "Inter" }}>{this.strings().reisswolftCheckinSecondtitle}</h2>
                <h3 className="Reisswolf-inputtext" style={{ color: ClientConfig.mainColor, fontFamily: "Inter" }}>{this.strings().reisswolftInputCompany}<span style={{ color: "red" }}>*</span></h3>
            </div>)
        }
    }

    public componentDidMount = () => {
        const { language, languageData, currentFlow, layoutData } = this.props;
        // Set custom attributes list
        var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);
        const attributes = currentLanguageOptions && currentLanguageOptions.checkInCustomAttributes ? currentLanguageOptions.checkInCustomAttributes : [];
        this.setState({ companyAttributesList: attributes });
        // Set custom attributes
        const customAttributes = attributes.map(a => ({ name: a.attributeName, value: '' }));
        if (layoutData.code !== "Sef" && layoutData.code !== "Seftest" && currentFlow === "GroupCheckin") {
            customAttributes.push({ name: 'Antal deltagere', value: '' })
        }
        this.setState({ customAttributes });

        if (layoutData.code === "OfficePartner" || layoutData.code === "EDForhandler" || layoutData.code === "Sef" || layoutData.code === "Seftest" || layoutData.code === "Kalundborg" || layoutData.code === "Comm2ig" || layoutData.code === "Reisswolf" || layoutData.code === "Kjaergaard" || layoutData.code === "Meneta") {
            this.setState({ focusedValueKey: customAttributes[0].name });
        }

        if ((currentFlow !== "GroupCheckin" as FlowType && layoutData.code === "Sef") || (currentFlow !== "GroupCheckin" as FlowType && layoutData.code === "Seftest")) {
            //console.log(currentFlow);
            this.nextButtonClicked();
        }

        this.setButtonState();
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props;

        if (layoutData.code === "Reisswolf") {
            return (
                <button className="continue2-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked} disabled={buttonDisabled}>{this.strings().continue}</button>
            )
        } else if (layoutData.code === "Meneta") {
            return (<div className="custom-fields-button-container" style={{ height: '200px' }}>
                <PrimaryButton className="custom-fields-button" style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>)
        } else {
            return (<div className="custom-fields-button-container">
                <PrimaryButton className="custom-fields-button" style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>)
        }

    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    render() {
        const { currentPage, layoutData } = this.props;
        const { buttonDisabled } = this.state;

        if (currentPage === "CustomFieldsPage") {
            return (
                <div className="custom-fields-container">
                    <div style={StylesUtil.getCustomTextFieldsContainerStyle(layoutData)}>
                        {this.addCustomFieldsPageTitle()}
                        {this.renderAttributesTextFields()}
                    </div>
                    {this.addNextButton(buttonDisabled)}
                    {this.addKeyboard()}
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { language } = state.localization;
    const { data } = state.customerData;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData, languageData } = state.configData;

    return {
        currentPage, currentFlow, data, language, layoutData, conditionData, configData, departmentsData, languageData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCustomerData, setCustomerCount, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomFieldsPage);
